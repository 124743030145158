import { gql } from 'graphql-request';

export const SETTINGS_FIELDS = gql`
  fragment SettingsFields on SettingsDto {
    setting
    value
  }
`;

export const getMinVersion = gql`
  query getVersion {
    getVersion {
      ...SettingsFields
    }
  }

  ${SETTINGS_FIELDS}
`;

export const createSetting = gql`
  mutation createOrUpdateVersion($setting: SettingCreateInput!) {
    createOrUpdateVersion(setting: $setting) {
      ...SettingsFields
    }
  }
  ${SETTINGS_FIELDS}
`;

const SettingsGraphQL = {
  queries: {
    getMinVersion,
  },
  mutations: {
    createSetting,
  },
};

export default SettingsGraphQL;
