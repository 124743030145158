import DetailActivity from '@components/detailActivityBox/DetailActivityBox';
import { Activity } from '@models/activity.model';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import COLORS from '@utils/color';
import { useRef, useEffect, useState } from 'react';

interface DetailBoxProps {
  groupedActivities: any;
  handleLoad: any;
  page: number;
  pages: number;
  delegateOrganizationId: string | null | undefined;
}

const ListItemSubcontractor = ({
  groupedActivities,
  handleLoad,
  page,
  pages,
  delegateOrganizationId,
}: DetailBoxProps) => {
  const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
  const projectIdRef = useRef<string | null | undefined>(delegateOrganizationId);
  const pageRef = useRef<number>(page);
  const pagesRef = useRef<number>(pages);
  const handleLoadRef = useRef<any>(handleLoad);
  const groupedActivitiesRef = useRef<any>(groupedActivities);
  const { organizationId } = useAppSelector((state: RootState) => state.organization);

  useEffect(() => {
    projectIdRef.current = delegateOrganizationId;
  }, [delegateOrganizationId]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    pagesRef.current = pages;
  }, [pages]);

  useEffect(() => {
    handleLoadRef.current = handleLoad;
  }, [handleLoad]);

  useEffect(() => {
    groupedActivitiesRef.current = groupedActivities;
  }, [groupedActivities]);

  const handleMore = () => {
    if (pageRef.current < pagesRef.current) {
      handleLoadRef.current(pageRef.current + 1, projectIdRef.current);
    }
  };

  const handleScroll = () => {
    const element = document.getElementById('project-list');
    if (element) {
      const isAtEnd = element.scrollTop + element.clientHeight >= element.scrollHeight - 1;
      if (isAtEnd && !hasReachedEnd) {
        handleMore();
        setHasReachedEnd(true);
      } else if (!isAtEnd) {
        setHasReachedEnd(false);
      }
    }
  };

  useEffect(() => {
    const element = document.getElementById('project-list');
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <Box
      id="project-list"
      sx={{
        flex: 1,
        marginRight: '20px',
        overflowY: 'auto',
        paddingRight: '10px',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
      }}>
      {Object.entries(groupedActivities).map(([date, activitiesForDate]) => (
        <Box key={date} sx={{ marginBottom: '15px' }}>
          <Typography sx={{ textAlign: 'center', color: COLORS.lightGray, marginBottom: '5px' }}>{date}</Typography>
          {(activitiesForDate as Activity[]).map((activity) => (
            <DetailActivity
              key={activity.activityId}
              title={
                activity?.organizationId == organizationId
                  ? `${activity?.user?.firstName} ${activity?.user?.lastName}` || 'Titulo'
                  : `Subcontractor: ${activity?.organization?.name}`
              }
              subtitle={activity.type || ''}
              description={activity.description || ''}
              createdAt={activity.createdAt || ''}
              picture={activity.picture || ''}
              name={activity.name || ''}
              timeOfIncident={activity.time || ''}
              delay={`${activity?.delay} ${activity?.delayUnitOfMeasure}` || ''}
              newStatus={activity.newStatus || ''}
              newPercentage={activity.newPercentage || 0}
              taskName={activity?.task?.name || ''}
              taskType={activity?.task?.taskType || ''}
              projectName={activity?.project?.name || ''}
              projectId={activity?.project?.projectId || ''}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ListItemSubcontractor;
