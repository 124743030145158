import { gql } from 'graphql-request';

export const ACTIVITY_FIELDS = gql`
  fragment ActivityFields on ActivityDto {
    activityId
    type
    name
    time
    category
    description
    picture
    incidentTime
    delay
    delayUnitOfMeasure
    newStatus
    newPercentage
    createdAt
    userId
    taskId
    organizationId
    organization {
      name
    }
    task {
      name
      taskType
    }
    user {
      firstName
      lastName
    }
    project {
      name
      projectId
    }
  }
`;

export const getActivities = gql`
  query getActivities($projectId: String!, $page: Float, $limit: Float) {
    getActivities(projectId: $projectId, page: $page, limit: $limit) {
      total
      items {
        ...ActivityFields
      }
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const getActivitiesByProjects = gql`
  query getActivitiesByProjects($projectsId: [String!]!, $page: Float, $limit: Float) {
    getActivitiesByProjects(projectsId: $projectsId, page: $page, limit: $limit) {
      total
      items {
        ...ActivityFields
      }
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const getActivitiesByOrganizationId = gql`
  query getActivitiesByOrganizationId($organizationId: String!, $page: Float, $limit: Float) {
    getActivitiesByOrganizationId(organizationId: $organizationId, page: $page, limit: $limit) {
      total
      items {
        ...ActivityFields
      }
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const createActivity = gql`
  mutation createActivity($activity: ActivityCreateInput!) {
    createActivity(activity: $activity) {
      ...ActivityFields
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const updateActivity = gql`
  mutation updateActivity($activity: ActivityUpdateInput!) {
    updateActivity(activity: $activity) {
      ...ActivityFields
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const deleteActivity = gql`
  mutation deleteActivity($id: String!) {
    deleteActivity(id: $id) {
      affected
      messages
    }
  }
`;

const ActivitiesGraphQL = {
  queries: {
    getActivities,
    getActivitiesByOrganizationId,
    getActivitiesByProjects,
  },
  mutations: {
    createActivity,
    updateActivity,
    deleteActivity,
  },
};

export default ActivitiesGraphQL;
