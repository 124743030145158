import { Box, Typography } from '@mui/material';
import Button from '@components/ui/Button';
import React, { useEffect, useState } from 'react';
import CustomModal from '@components/generic/CustomModal';
import { graphQlClient } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import COLORS from '@utils/color';
import { Activity } from '@models/activity.model';
import ActivitiesGraphQL from '@graphql/activity.queries';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import DetailBox from '@components/detailBox/DetailBox';
import ProjectsGraphQL from '@graphql/project.queries';
import { Project, getProjectsRequest } from '@models/project.model';
import { Refresh } from '@mui/icons-material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ListItem from '@components/list/list';
import SelectInput from '@components/ui/SelectInput';

const DashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [activities, setActivities] = useState<Activity[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const limit = 7;
  const [showNewSubcontractorModal, setShowNewSubcontractorModal] = useState<boolean>(false);
  const localeCommon = useTranslation('common');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);

  useEffect(() => {
    getProjects(true);
  }, []);

  const getProjects = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getProjectsRequest = await graphQlClient.request(ProjectsGraphQL.queries.getProjects);
      setProjects(data.getProjects);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    const projectIds = projects.map((project) => project.projectId);
    setActivities([]);
    setPages(0);
    if (selectedProjectId) {
      getActivities(true, [selectedProjectId], 1, limit);
    } else {
      getActivities(true, projectIds, 1, limit);
    }
  };

  useEffect(() => {
    const projectIds = projects.map((project) => project.projectId);
    if (projectIds.length > 0) {
      getActivities(true, projectIds, 1, limit);
    }
  }, [projects]);

  useEffect(() => {
    setActivities([]);
    setPages(0);
    const projectIds = projects.map((project) => project.projectId);
    if (selectedProjectId) {
      getActivities(true, [selectedProjectId], 1, limit);
    } else {
      getActivities(true, projectIds, 1, limit);
    }
  }, [selectedProjectId]);

  const getActivities = async (initialize = false, projectsId: (string | undefined)[], page: number, limit: number) => {
    try {
      const data: any = await graphQlClient.request(ActivitiesGraphQL.queries.getActivitiesByProjects, {
        projectsId,
        page,
        limit,
      });
      setPages(Math.ceil(data.getActivitiesByProjects.total / limit));
      if (initialize) {
        setActivities(data.getActivitiesByProjects.items);
      } else {
        setActivities([...activities, ...data.getActivitiesByProjects.items]);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLoad = (newPage: number, id: string) => {
    const projectIds = projects.map((project) => project.projectId);
    setPage(newPage);
    if (id) {
      getActivities(false, [id], newPage, limit);
    } else {
      getActivities(false, projectIds, newPage, limit);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    return isToday
      ? 'Today'
      : `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(
          2,
          '0',
        )}/${date.getFullYear()}`;
  };

  const groupByDate = (activities: any[]) => {
    return activities.reduce(
      (acc, activity) => {
        const dateKey = formatDate(activity.createdAt);

        if (!acc[dateKey]) acc[dateKey] = [];
        acc[dateKey].push(activity);
        return acc;
      },
      {} as Record<string, any[]>,
    );
  };

  useEffect(() => {
    setGroupedActivities(groupByDate(activities));
  }, [activities]);

  const handleChange = (event: React.SyntheticEvent, value: any | null) => {
    if (value?.projectId) {
      setSelectedProjectId(value?.projectId);
    }
  };

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <Box className="my-6 mx-8" sx={{ flex: 1, height: '82%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
          <Typography sx={{ alignContent: 'center' }}>Organization Feed</Typography>
          <Box sx={{ width: 300, display: 'flex' }}>
            <SelectInput
              label={''}
              placeholder={'Select Project'}
              items={projects}
              value={selectedProjectId}
              valueKey="projectId"
              labelKey="name"
              onChange={handleChange}
              error={false}
            />
            <Button
              onClick={() => setSelectedProjectId(null)}
              color="primary"
              sx={{ height: '16px', width: '16px', borderRadius: 20, margin: '5px' }}>
              <FilterAltOffIcon sx={{ fontSize: '16px' }} />
            </Button>
            <Button
              onClick={handleRefresh}
              color="primary"
              sx={{ height: '16px', width: '16px', borderRadius: 20, margin: '5px' }}>
              <Refresh sx={{ fontSize: '16px' }} />
            </Button>
          </Box>
        </Box>
        <Box sx={{ height: '100%' }}>
          <ListItem
            projectId={selectedProjectId}
            page={page}
            pages={pages}
            handleLoad={handleLoad}
            groupedActivities={groupedActivities}
          />
        </Box>
      </Box>

      <Box sx={{ width: '360px' }}>
        <Typography sx={{ marginBottom: 4, marginTop: 4 }}>Active Projects</Typography>
        <Box sx={{ flex: 1, marginRight: '20px', overflowY: 'auto' }}>
          {projects?.map((project: any) => {
            return (
              <DetailBox
                key={project.projectId}
                title={project.name}
                subtitle={project.address}
                link={`/projects/${project.projectId}`}
              />
            );
          }) || null}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPage;
