export const TaskOptions = [
  { id: 'Foundation', name: 'Foundation' },
  { id: 'Framing', name: 'Framing' },
  { id: 'Roofing', name: 'Roofing' },
  { id: 'Electrical', name: 'Electrical' },
  { id: 'Mechanical', name: 'Mechanical' },
  { id: 'Plumbing', name: 'Plumbing' },
  { id: 'Insulation', name: ' Insulation' },
  { id: 'HVAC', name: 'HVAC' },
  { id: 'Other', name: 'Other' },
];

export const TimeOptions = [
  { id: 'hours', name: 'hours' },
  { id: 'days', name: 'days' },
  { id: 'weeks', name: 'weeks' },
];

export const TaskStatusOptions = [
  { id: 'Completed', name: 'Completed', color: '#3cbde6' },
  { id: 'On Track', name: 'On Track', color: '#7eb81c' },
  { id: 'Delayed', name: 'Delayed', color: '#ffe100' },
  { id: 'On Hold', name: 'On Hold', color: '#f77239' },
  { id: 'Unassigned', name: 'Unassigned', color: '#212f3c' },
];

export const PeriodOptions = [
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
];

export const FullTimeOptions = [
  { id: 'FullTime', name: 'FullTime' },
  { id: 'PartTime', name: 'PartTime' },
];

export const WeightOptions = [
  { id: 'metric', name: 'metric' },
  { id: 'imperial', name: 'imperial' },
];

export const MetricWeightOptions = [
  { id: 'item', name: 'item' },
  { id: 'millimeters', name: 'millimeters' },
  { id: 'centimeters', name: 'centimeters' },
  { id: 'meters', name: 'meters' },
  { id: 'kilometers', name: 'kilometers' },
  { id: 'grams', name: 'grams' },
];

export const ImperialWeightOptions = [
  { id: 'item', name: 'item' },
  { id: 'inches', name: 'inches' },
  { id: 'feet', name: 'feet' },
  { id: 'yards', name: 'yards' },
  { id: 'miles', name: 'miles' },
  { id: 'pounds', name: 'pounds' },
];

export const EventTypeOptions = [
  { id: 'Incident', name: 'Incident' },
  { id: 'Visitor', name: 'Visitor' },
  { id: 'Permit', name: 'Permit' },
  { id: 'Communication', name: 'Communication' },
  { id: 'Inspection', name: 'Inspection' },
  { id: 'Other', name: 'Other' },
];

export const IncidentTypeOptions = [
  { id: 'Weather', name: 'Weather' },
  { id: 'Accident', name: 'Accident' },
  { id: 'Materials issue', name: 'Materials issue' },
  { id: 'Equipment issue', name: 'Equipment issue' },
  { id: 'Add incident type', name: 'Add incident type' },
];

export const InspectionTypeOptions = [
  { id: 'Foundation', name: 'Foundation' },
  { id: 'Electric', name: 'Electric' },
  { id: 'HVAC', name: 'HVAC' },
  { id: 'Mechanical', name: 'Mechanical' },
  { id: 'Roofing', name: 'Roofing' },
  { id: 'Interior', name: 'Interior' },
  { id: 'Saftey', name: 'Saftey' },
  { id: 'Final', name: 'Final' },
  { id: 'Exterior', name: 'Exterior' },
  { id: 'Appliance', name: 'Appliance' },
  { id: 'Fixtures', name: 'Fixtures' },
  { id: 'Wooden supports', name: 'Wooden supports' },
  { id: 'Framing', name: 'Framing' },
];
