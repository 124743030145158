export interface Organization {
  organizationId: string;
  name: string;
  ownerEmail?: string;
  ownersNames?: string;
  ownersEmails?: string;
  status?: OrganizationStatus;
  createdAt: Date;
  lastUpdatedAt: Date;
  showAsSubcontractor: boolean;
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface getOrganizationsRequest {
  getOrganizations: {
    organizations: Organization[];
    count: number;
  };
}

export interface getOrganizationsForTaskCreationRequest {
  getOrganizationsForTaskCreation: {
    organizations: Organization[];
    count: number;
  };
}

export interface getOrganizationRequest {
  getOrganization: Organization;
}

export interface createOrUpdateOrganizationRequest {
  createOrganization?: Organization;
  updateOrganization?: Organization;
}

export interface disableOrEnableOrganizationRequest {
  disableOrganization?: Organization;
  enableOrganization?: Organization;
}
