import CreateEditOrganizationModal from '@components/organization/CreateEditOrganizationModal';
import CustomModal from '@components/generic/CustomModal';
import DisableOrganizationModal from '@components/organization/DisableOrganizationModal';
import DropdownMenu from '@components/generic/DropdownMenu';
import EnableOrganizationModal from '@components/organization/EnableOrganizationModal';
import SplitListCell from '@components/generic/SplitListCell';
import { EditIcon24, TrashIcon24 } from '@components/ui/Icons';
import Search from '@components/ui/Search';
import UserInfoModal from '@components/user/UserInfoModal';
import { useTranslation } from '@hooks/useTranslation';
import { Organization, OrganizationStatus } from '@models/organization.model';
import { NoAccounts } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useState } from 'react';

// TODO: With some work this could become generic if we replace documents for a generic type

interface OrganizationsListProps {
  // deleteDocument
  handleDisableOrganization: (organizationId: string) => void;
  handleEnableOrganization: (organizationId: string) => void;
  handleEditOrganization: (
    name: string,
    email: string,
    firstName: string,
    lastName: string,
    organizationId: string,
  ) => void;
  updateShowAsSubcontractor: (name: string, showAsSubcontractor: boolean, organizationId: string) => void;
  // common
  count: number;
  organizations: Organization[];
  // search
  search: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // filter
  filter: string;
  handleFilterChange: (filter: string) => void;
  // order
  orderDirection: 'asc' | 'desc';
  orderBy: string | null;
  handleRequestSort: (property: string) => void;
  // pagination
  page: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OrganizationsList: React.FC<OrganizationsListProps> = ({
  count,
  organizations,
  search,
  handleSearchChange,
  filter,
  handleFilterChange,
  orderBy,
  orderDirection,
  handleRequestSort,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleEnableOrganization,
  handleDisableOrganization,
  handleEditOrganization,
  updateShowAsSubcontractor,
}) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmEnableModal, setShowConfirmEnableModal] = useState<boolean>(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState<boolean>(false);
  // TODO: With some work this could become generic
  const [organizationToDelete, setOrganizationToDelete] = useState<Organization | undefined>(undefined);
  const [organizationToEdit, setOrganizationToEdit] = useState<Organization | undefined>(undefined);
  const [organizationToEnable, setOrganizationToEnable] = useState<Organization | undefined>(undefined);
  const [userToDetail, setUserToDetail] = useState<any | undefined>(undefined);

  const localeCommon = useTranslation('common');
  const localeOrganizations = useTranslation('organizations');

  const toggleConfirmDeleteModal = (organization?: Organization) => {
    setShowConfirmDeleteModal(true);
    setOrganizationToDelete(organization);
  };

  const toggleEnableConfirmModal = (organization?: Organization) => {
    setOrganizationToEnable(organization);
    setShowConfirmEnableModal(true);
  };

  const disableOrganization = () => {
    if (showConfirmDeleteModal) {
      handleDisableOrganization(organizationToDelete?.organizationId || '');
    }
    setShowConfirmDeleteModal(false);
  };

  const toggleEditModal = (organization: Organization) => {
    setOrganizationToEdit(organization);
    setShowEditModal(true);
  };

  const enableOrganization = () => {
    if (showConfirmEnableModal) {
      handleEnableOrganization(organizationToEnable?.organizationId || '');
    }
    setShowConfirmEnableModal(false);
    setOrganizationToEnable(undefined);
  };

  const modalClose = () => {
    setShowConfirmDeleteModal(false);
    setShowEditModal(false);
    setShowConfirmEnableModal(false);
    setShowUserInfoModal(false);
    setUserToDetail(undefined);
    setOrganizationToDelete(undefined);
    setOrganizationToEdit(undefined);
  };

  const showUserInfo = (email: string, organization: string) => {
    setShowUserInfoModal(true);
    setUserToDetail({ email, organization });
  };

  const sortedTableHeaderCell = ({
    label,
    property,
    align,
  }: {
    label: string;
    property: string;
    align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined;
  }) => {
    return (
      <TableCell sortDirection={orderBy === property ? orderDirection : false} align={align ?? 'inherit'}>
        <TableSortLabel
          active={orderBy === property}
          direction={orderBy === property ? orderDirection : 'asc'}
          onClick={() => handleRequestSort(property)}>
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const activeFilterItems = [
    { value: 'All', label: localeCommon['all'] },
    { value: 'Active', label: localeCommon['active'] },
    { value: 'Disabled', label: localeCommon['disabled'] },
  ];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: (typeof organizations)[0]) => {
    const isChecked = event.target.checked;
    updateShowAsSubcontractor(row.name, isChecked, row.organizationId);
  };

  return (
    <>
      <Box>
        <Box className="flex items-center mb-2">
          <Search value={search} onChange={handleSearchChange} />
          <DropdownMenu items={activeFilterItems} applyFilter={handleFilterChange} filter={filter} />
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {sortedTableHeaderCell({ label: 'Name', property: 'name' })}
                <TableCell align="center">{localeOrganizations['ownersEmails']}</TableCell>
                <TableCell align="center">{localeOrganizations['ownersNames']}</TableCell>
                <TableCell align="center">{'Show as Subcontractor'}</TableCell>
                <TableCell align="center">{localeCommon['actions']}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.map((row, idx) => (
                <TableRow key={`${idx}-${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" title={row.name}>
                    {row.name && row.name.slice(0, 25) + (row.name.length > 25 ? '...' : '')}
                  </TableCell>
                  <TableCell component="th" scope="row" className="cursor-pointer text-teal-600">
                    <SplitListCell
                      list={row.ownersEmails || ''}
                      showDetail={(email) => showUserInfo(email, row.organizationId)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" className="cursor-pointer">
                    <SplitListCell list={row.ownersNames || ''} />
                  </TableCell>
                  <TableCell component="th" scope="row" className="cursor-pointer">
                    <Checkbox
                      checked={row.showAsSubcontractor}
                      onChange={(event) => handleCheckboxChange(event, row)}
                      inputProps={{ 'aria-label': 'Show as subcontractor' }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ textAlign: 'center' }} width={140}>
                    {
                      <Box className="flex justify-center">
                        {row.status === OrganizationStatus.ACTIVE && (
                          <>
                            <IconButton onClick={() => toggleEditModal(row)}>
                              <EditIcon24 color="error" sx={{ margin: 1 }} />
                            </IconButton>
                            <IconButton onClick={() => toggleConfirmDeleteModal(row)}>
                              <NoAccounts color="error" sx={{ margin: 1 }} />
                            </IconButton>
                          </>
                        )}
                        {row.status === OrganizationStatus.DISABLED && (
                          <IconButton onClick={() => toggleEnableConfirmModal(row)}>
                            <TrashIcon24 color="error" sx={{ margin: 1 }} />
                          </IconButton>
                        )}
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <CustomModal isOpen={showConfirmDeleteModal} onClose={modalClose}>
        <DisableOrganizationModal
          modalClose={modalClose}
          disableOrganization={disableOrganization}
          organization={organizationToDelete}
        />
      </CustomModal>

      <CustomModal isOpen={showConfirmEnableModal} onClose={modalClose}>
        <EnableOrganizationModal
          modalClose={modalClose}
          enableOrganization={enableOrganization}
          organization={organizationToEnable}
        />
      </CustomModal>

      <CustomModal isOpen={showUserInfoModal} onClose={modalClose}>
        <UserInfoModal
          modalClose={modalClose}
          userToShow={userToDetail?.email}
          organization={userToDetail?.organization}
        />
      </CustomModal>

      <CustomModal isOpen={showEditModal} onClose={modalClose}>
        {/* //TODO adjust saving */}
        <CreateEditOrganizationModal
          editing={true}
          modalClose={modalClose}
          editOrganization={handleEditOrganization}
          saving={false}
          initialData={{
            organizationId: organizationToEdit?.organizationId || '',
            name: organizationToEdit?.name || '',
          }}
        />
      </CustomModal>
    </>
  );
};

export default OrganizationsList;
