import { gql } from 'graphql-request';

export const TASK_FIELDS = gql`
  fragment TaskFields on TaskDto {
    taskId
    name
    description
    taskType
    otherType
    manHours
    startTime
    endTime
    timeConstraint
    typeOfTimeConstraint
    presedentTaskId
    presedentEventId
    projectId
    status
    equipments {
      equipmentId
      name
      supplierOwner
      time
      hoursUsed
      amount
      costPerHour
      taskId
    }
    materials {
      materialId
      name
      supplier
      time
      amount
      amountType
      amountSystem
      costPerUnit
      taskId
    }
    documents {
      documentId
      name
      url
    }
    crews {
      crewId
      name
    }
    delegateTaskId
    delegateOrganizationId
    delegateOrganization {
      organizationId
      name
    }
    originalTaskId
    originalProjectId
    originalOrganizationId
    unassigned
  }
`;

export const TASK_STATS_FIELDS = gql`
  fragment TaskStatusFields on StatsDto {
    totalTasks
    completedTasks
  }
`;

export const getTaskStats = gql`
  query getTaskStats($projectId: String!) {
    getTaskStats(projectId: $projectId) {
      ...TaskStatusFields
    }
  }
  ${TASK_STATS_FIELDS}
`;

export const getAllMaterialsAndEquipments = gql`
  query getAllMaterialsAndEquipments($projectId: String!, $unassigned: Boolean!) {
    getAllMaterialsAndEquipments(projectId: $projectId, unassigned: $unassigned) {
      equipments {
        equipmentId
        name
        supplierOwner
        time
        hoursUsed
        amount
        costPerHour
        taskId
        taskName
        originalProjectId
        originalOrganizationName
        unassigned
      }
      materials {
        materialId
        name
        supplier
        time
        amount
        amountType
        costPerUnit
        taskId
        taskName
        originalProjectId
        originalOrganizationName
        unassigned
      }
    }
  }
`;

export const getTasks = gql`
  query getTasks($projectId: String!) {
    getTasks(projectId: $projectId) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
`;

export const getTasksByOriginalOrganizationId = gql`
  query getTasksByOriginalOrganizationId($originalOrganizationId: String!) {
    getTasksByOriginalOrganizationId(originalOrganizationId: $originalOrganizationId) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
`;

export const getTasksByOrganizationId = gql`
  query getTasksByOrganizationId($organizationId: String!) {
    getTasksByOrganizationId(organizationId: $organizationId) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
`;

export const createTask = gql`
  mutation createTask($task: TaskCreateInput!) {
    createTask(task: $task) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
`;

export const updateTask = gql`
  mutation updateTask($task: TaskUpdateInput!) {
    updateTask(task: $task) {
      ...TaskFields
    }
  }
  ${TASK_FIELDS}
`;

export const deleteTask = gql`
  mutation deleteTask($id: String!) {
    deleteTask(id: $id) {
      affected
      messages
    }
  }
`;

const TasksGraphQL = {
  queries: {
    getTasks,
    getAllMaterialsAndEquipments,
    getTaskStats,
    getTasksByOriginalOrganizationId,
    getTasksByOrganizationId,
  },
  mutations: {
    createTask,
    updateTask,
    deleteTask,
  },
};

export default TasksGraphQL;
