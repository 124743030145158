import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#edf4f5',
    color: '#859194',
    fontSize: 12,
    padding: '4px 8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px 8px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: 'none',
  height: '34px',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#edf4f5',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface Item {
  cost: number;
  item: string;
  date: string;
  paidTo: string;
  status: string;
}

interface BudgetTableProps {
  inventory: any;
}

export default function BudgetTable({ inventory }: BudgetTableProps) {
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  function formatNumber(value: number) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return (
    <TableContainer sx={{ marginBottom: 2 }} component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Cost</StyledTableCell>
            <StyledTableCell>Item</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Task</StyledTableCell>
            <StyledTableCell>Subcontracted</StyledTableCell>
            <StyledTableCell>Organization</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventory?.map((item: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell
                component="th"
                scope="row">{`$ ${item.equipmentId ? formatNumber(item.costPerHour * item.hoursUsed) : formatNumber(item.amount * item.costPerUnit)}`}</StyledTableCell>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell>{item.equipmentId ? 'Equipment' : 'Material'}</StyledTableCell>
              <StyledTableCell>{formatDate(item.time)}</StyledTableCell>
              <StyledTableCell>{item.taskName}</StyledTableCell>
              <StyledTableCell>
                {item.unassigned ? 'Previous contractor' : item.originalProjectId ? 'Yes' : 'No'}
              </StyledTableCell>
              <StyledTableCell>{item.originalOrganizationName ? item.originalOrganizationName : ''}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
