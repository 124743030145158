import { gql } from 'graphql-request';

export const ORGANIZATION_FIELDS = gql`
  fragment OrganizationFields on OrganizationDto {
    organizationId
    name
    status
    ownerEmail
    ownersEmails
    ownersNames
    showAsSubcontractor
  }
`;

export const getOrganization = gql`
  query getOrganization($id: String!) {
    getOrganization(id: $id) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const getOrganizations = gql`
  query getOrganizations($organizations: GetOrganizationsInput!) {
    getOrganizations(organizations: $organizations) {
      organizations {
        ...OrganizationFields
      }
      count
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const getOrganizationsForTaskCreation = gql`
  query getOrganizationsForTaskCreation($organizations: GetOrganizationsInput!) {
    getOrganizationsForTaskCreation(organizations: $organizations) {
      organizations {
        ...OrganizationFields
      }
      count
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const createOrganization = gql`
  mutation createOrganization($organization: OrganizationCreateInput!) {
    createOrganization(organization: $organization) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const updateOrganization = gql`
  mutation updateOrganization($organization: OrganizationUpdateInput!) {
    updateOrganization(organization: $organization) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const disableOrganization = gql`
  mutation disableOrganization($id: String!) {
    disableOrganization(id: $id) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const enableOrganization = gql`
  mutation enableOrganization($id: String!) {
    enableOrganization(id: $id) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

const OrganizationsGraphQL = {
  queries: {
    getOrganization,
    getOrganizations,
    getOrganizationsForTaskCreation,
  },
  mutations: {
    createOrganization,
    updateOrganization,
    disableOrganization,
    enableOrganization,
  },
};

export default OrganizationsGraphQL;
