import { Box, Icon, Typography } from '@mui/material';
import COLORS from '@utils/color';
import { useHistory } from 'react-router-dom';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import NearMeIcon from '@mui/icons-material/NearMe';

interface DetailBoxProps {
  title: string;
  subtitle: string;
  description: string;
  createdAt: string | Date;
  picture: string;
  name: string;
  timeOfIncident: string | Date;
  delay: string;
  newStatus: string;
  newPercentage: number;
  taskName: string;
  taskType: string;
  link?: string;
  projectName?: string;
  projectId?: string;
}

const DetailActivity = ({
  title,
  subtitle,
  description,
  createdAt,
  picture,
  name,
  timeOfIncident,
  delay,
  newStatus,
  newPercentage,
  taskName,
  taskType,
  link,
  projectName,
  projectId,
}: DetailBoxProps) => {
  const history = useHistory();

  const navigateToLink = () => {
    if (link) {
      history.push(link);
    }
  };

  const handleDownload = () => {
    if (picture) {
      window.open(picture, '_blank');
    }
  };

  function formatTime(dateInput: Date | string): string {
    const date = new Date(dateInput);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutesStr} ${ampm}`;
  }

  return (
    <Box
      onClick={navigateToLink}
      sx={{
        backgroundColor: COLORS.white,
        border: `2px solid ${COLORS.lightBlue}`,
        marginBottom: 1,
        padding: '6px 4px',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Box sx={{}}>
        <Typography fontWeight={600} variant="h6">
          {title}
        </Typography>
        <Box sx={{ display: 'flex', color: COLORS.lightBlue, alignItems: 'center' }}>
          {subtitle === 'Picture' ? (
            <CameraAltOutlinedIcon style={{ height: 20, width: 20 }} />
          ) : subtitle === 'Note' ? (
            <NoteOutlinedIcon style={{ height: 20, width: 20 }} />
          ) : subtitle === 'Incident' ? (
            <ReportOutlinedIcon style={{ height: 20, width: 20 }} />
          ) : subtitle === 'Geofence' ? (
            <NearMeIcon style={{ height: 20, width: 20 }} />
          ) : (
            <ChangeCircleOutlinedIcon style={{ height: 20, width: 20 }} />
          )}
          <Typography sx={{ paddingLeft: 0.5 }} color={COLORS.lightGray} variant="subtitle1">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'grid', color: COLORS.lightGray }}>
        <Typography sx={{ width: '100%', textAlign: 'center' }} variant="subtitle1">
          {formatTime(createdAt)}
        </Typography>
        {timeOfIncident && subtitle !== 'Geofence' && (
          <Typography
            fontSize={14}
            sx={{ width: '100%', textAlign: 'center' }}
            variant="subtitle1">{`Time of Incident: ${formatTime(timeOfIncident)}`}</Typography>
        )}
        {taskName && (
          <Typography
            fontSize={12}
            sx={{ width: '100%', textAlign: 'center' }}
            variant="subtitle1">{`Task: ${taskName}`}</Typography>
        )}
        {projectName && (
          <Typography
            fontSize={12}
            sx={{ width: '100%', textAlign: 'center' }}
            variant="subtitle1">{`Project: ${projectName}`}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'Flex',
          justifyContent: 'end',
          color: COLORS.lightBlue,
          // maxHeight: 68,
          minHeight: 68,
        }}>
        {(description || subtitle === 'Task Status' || subtitle === 'Geofence') && (
          <Box sx={{ display: 'Flex', alignItems: 'center', color: COLORS.white, width: 200, height: '100%' }}>
            <Box
              sx={{
                alignContent: 'center',
                textAlign: 'center',
                backgroundColor: COLORS.lightBlue,
                color: COLORS.white,
                width: 25,
                height: '100%',
              }}>
              <AnnouncementIcon style={{ height: 20, width: 20 }} />
            </Box>
            <Box
              sx={{
                color: COLORS.lightGray,
                padding: 0.5,
                width: 175,
                height: '100%',
                textAlign: 'end',
                alignContent: 'center',
                backgroundColor: '#e9f4f5',
              }}>
              {subtitle === 'Task Status' ? (
                <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Typography fontWeight={600} fontSize={12} variant="subtitle1">
                    {newStatus}
                  </Typography>
                  <CircleIcon
                    style={{
                      height: 10,
                      width: 10,
                      marginLeft: 2,
                      color:
                        newStatus === 'On Track'
                          ? COLORS.green
                          : newStatus === 'Delayed'
                            ? COLORS.yellow
                            : newStatus === 'completed'
                              ? COLORS.lightBlue
                              : COLORS.orange,
                    }}
                  />
                </Box>
              ) : (
                <Typography fontWeight={600} fontSize={12} variant="subtitle1">
                  {name}
                </Typography>
              )}
              {(subtitle === 'Incident' || subtitle === 'Task Status') && (
                <Typography fontStyle={'italic'} fontSize={12} variant="subtitle1">
                  {subtitle === 'Incident' ? `delay: ${delay}` : `progress:${newPercentage}%`}
                </Typography>
              )}
              <Typography fontSize={12} variant="subtitle1">
                {description}
              </Typography>
            </Box>
            {picture && (
              <Box
                onClick={handleDownload}
                sx={{
                  alignItems: 'center',
                  color: COLORS.lightBlue,
                  width: '100%',
                  height: '100%',
                  marginLeft: 1,
                  cursor: 'pointer',
                }}>
                <img src={picture} style={{ height: '100%', width: '100%' }} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DetailActivity;
